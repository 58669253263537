const addPackageToCart = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const wellnessPackage = e.target.getAttribute("data-package-id");
    console.log('wellnessPackage ---> ', wellnessPackage);

    const fd = new FormData();
    fd.append("package", wellnessPackage);
    axios
        .post("/api/cart", fd)
        .then((res) => {
            if (
                res.data.status === "Success" &&
                res.data.message === "cart-updated"
            ) {
                const cart = document.getElementById("cart");
                // const footerCartCount = document.getElementById("footerCartCount");
                if (cart && res.data.data && res.data.data.cart) {
                    cart.innerHTML = res.data.data.cart.length;
                    // footerCartCount.innerHTML = res.data.data.cart.length;
                    // document.getElementById('footer_cart_section').style.display = 'block';
                    e.target.innerHTML = 'Added';
                }
                Toast.fire({
                    icon: "success",
                    title: `Package Added To <i class="fa fa-cart-plus" aria-hidden="true"></i>`,
                });
            }
        })
        .catch((err) => {
            console.error(err);
            if (err.response.data.message === "package-exists") {
                Toast.fire({
                    icon: "info",
                    title: `Package Already Added in <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>`,
                });
            } else if (err.response.data.message === "invalid-item") {
                Toast.fire({
                    icon: "info",
                    title: `Can't add the current package to <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>`,
                });
            }
        });
};

export { addPackageToCart };
