class Bus {
    constructor() {
        this.collection = {};
    }

    listen(identifier, fn) {
        this.collection[identifier] = this.collection[identifier] || [];
        this.collection[identifier].push(fn);
    }

    off(identifier, fn) {
        if (this.collection[identifier]) {
            for (var i = 0; i < this.collection[identifier].length; i++) {
                if (this.collection[identifier][i] === fn) {
                    this.collection[identifier].splice(i, 1);
                    break;
                }
            }
        }
    }

    emit(identifier, data) {
        if (this.collection[identifier]) {
            this.collection[identifier].forEach(function (fn) {
                fn(data);
            });
        }
    }
}

export default new Bus();
