const addTestToCart = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const item = e.target.getAttribute("data-item-id");
    const fd = new FormData();
    fd.append("item", item);
    axios
        .post("/api/cart", fd)
        .then((res) => {
            if (
                res.data.status === "Success" &&
                res.data.message === "cart-updated"
            ) {
                const cart = document.getElementById("cart");
                const footerCartCount = document.getElementById("footerCartCount");
                if (cart && res.data.data && res.data.data.cart) {
                    cart.innerHTML = res.data.data.cart.length;
                    footerCartCount.innerHTML = res.data.data.cart.length;
                    document.getElementById('footer_cart_section').style.display = 'block';
                    e.target.innerHTML = 'Added';
                }
                Toast.fire({
                    icon: "success",
                    title: `Item Added To <i class="fa fa-cart-plus" aria-hidden="true"></i>`,
                });
            }
        })
        .catch((err) => {
            if (err.response.data.message === "item-exists") {
                Toast.fire({
                    icon: "warning",
                    title: `Item Already in <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>`,
                });
            } else if (err.response.data.message === "invalid-item") {
                Toast.fire({
                    icon: "warning",
                    title: `Can't add the current item to <i class="fa fa-cart-arrow-down" aria-hidden="true"></i>`,
                });
            }
        });
};

export { addTestToCart };
